import { useMemo } from "react"
import { useFetcher } from "../common/useFetcher"
import { useSuspensedSWR } from "../common/useSuspensedSWR"
import { useSWR } from "../common/useSWR"


export const useUserRoutes = () => {
    const { data: response, mutate } = useSuspensedSWR("getUserAuthority",
        null
        , {
            dedupingInterval: 1000 * 60 * 60 * 24,
        })

    const routers = response?.data?.routers || []

    const role = useMemo(() => {
        const role = response?.data?.role
        let roleData = {}
        try {
            roleData = JSON.parse(role.role_data)
        } catch (error) {
            console.error(error)
        }

        if (roleData) {
            return {
                ...role,
                roleData
            }
        }
        return response?.data?.role || null
    }, [response])

    return {
        routers, 
        mutate,
        role
    }
}