import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { cn } from '@/utils'

export const Loading = ({ className, children }) => {
  return (
    <div className={cn('fixed flex justify-center items-center left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2', className)}>
      <Spin
        size="large"
        indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />}
      />
      {children}
    </div>

  )
}
