import { App, ConfigProvider } from 'antd'
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn';
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import React from 'react'
import { SWRConfig } from 'swr'
import { ErrorBoundary } from '@/components/ErrorBoundary'
import { SuspenseBoundary } from '@/components/SuspenseBoundary'
import { useFetcher } from '@/hooks'
import { Toaster,toast } from 'sonner';
import { useMemoizedFn } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { SIGN_IN_PATH } from '@/constants';
dayjs.locale('zh-cn');

const theme = {
    // cssVar: true,
    token: {
        colorPrimary: '#3975EA',
    },
}


// 接口status返回403时，跳转登录
const apiAuthMiddleware = (request) => {

}

export const AppProvider = ({ children }) => {
    const fetcher = useFetcher()
    const navigate = useNavigate()

    const apiAuthMiddleware = useMemoizedFn((next) => {
        return (key, fetcher, config) => {
          const extendedFetcher = async (...args) => {
            const response = await fetcher(...args)
            // if(response?.status === 401){
            //     navigate(LOGIN_PAGE_URL)
            // }
            return response
          }
          return next(key, extendedFetcher, config)
        }
      })

    return <ErrorBoundary>
        <ConfigProvider theme={theme} locale={zhCN} prefixCls="lipeibang-ant">
            <SWRConfig
                value={{
                    dedupingInterval: 1000 * 60 * 1,
                    revalidateOnFocus: false,
                    // revalidateOnMount: false,
                    revalidateOnReconnect: false,
                    refreshWhenOffline: false,
                    refreshWhenHidden: false,
                    fetcher,
                    // use: [apiAuthMiddleware],
                }}
            >
                <App>
                    <SuspenseBoundary>
                        {children}
                        <Toaster position="top-center" closeButton richColors/>
                    </SuspenseBoundary>
                </App>
            </SWRConfig>
        </ConfigProvider>
    </ErrorBoundary>
}
