import { Button } from 'antd'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-white">
      <div className="text-center">
        <h1 className="text-xl font-semibold">很抱歉，似乎发生了一些问题</h1>
        <Button className="mt-4" onClick={resetErrorBoundary}>
          刷新页面
        </Button>
      </div>
      <div className="mt-8 w-full max-w-4xl px-4 py-2 bg-gray-100 border rounded-md">
        <pre className="mt-2 text-xs overflow-auto">{error.stack}</pre>
      </div>
    </div>
  )
}

export const ErrorBoundary = ({ children, fallbackRender }) => {
  return (
    <ReactErrorBoundary
      fallbackRender={fallbackRender || ErrorBoundaryFallback}
      onReset={() => {
        location.reload()
      }}
    >
      {children}
    </ReactErrorBoundary>
  )
}

ErrorBoundary.displayName = 'ErrorBoundary'
