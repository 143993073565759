import { useSWR } from "./useSWR";

// export const useSuspensedSWR = (key, fetcher=null) => {
//     return useSWR(key, fetcher, {
//         suspense: true
//     })
// }

// @see https://medium.com/@tamis.mike/useswr-parallel-suspense-b41929a01098
export const useSuspensedSWR = (key, fetcher=null, args) => {
  
    let promise;
    try {
        return useSWR(key, fetcher, { ...args, suspense: true });
    } catch (err) {
        if (err.then) {
            promise = err;
        } else {
            throw err;
        }
    }
    const handler = {
        get() {
            throw promise;
        },
    };

    const proxedData = new Proxy({}, handler);

    return {
        data: proxedData,
    };
}





