import { API } from "@anlan/gs-api"


export const useMutationFetcher = () => {
    return async (key, { arg }) => {
        if (!API[key]) throw new Error(`API ${key} is not defined`)
        const requestConfig = API[key]
        const { url, method } = requestConfig
        let response
        if (method === 'get') {
            response = await fetch(`${url}?${new URLSearchParams(arg)}`, { method })
        } else if (method === 'post') {
            response = await fetch(url, {
                method,
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(arg)
            })
        } else {
            throw new Error(`API ${key} method is not defined`)
        }

        const data = await response.json()
        return data
    }
}



export const useFetcher = () => {
    const _request = async (payload) => {
        const [key] = payload
        if (!API[key]) throw new Error(`API ${key} is not defined`)
        const requestConfig = API[key]
        const { url, method } = requestConfig
        if (method === 'get') {
            const [, params] = payload
            const response = await fetch(`${url}?${new URLSearchParams(params)}`, { method })
            const data = await response.json()
            return data
        } else {
            const [, data] = payload
            const response = await fetch(url, {
                method,
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
            const resData = await response.json()
            return resData
        }

    }

    return async (payload) => {
        if (typeof payload === 'string') {
            const key = payload
            if (!API[key]) throw new Error(`API ${key} is not defined`)
            const requestConfig = API[key]
            const { url, method } = requestConfig
            const response = await fetch(url, { method })
            const data = await response.json()
            return data
        }

        // 判断第一个参数是不是数组
        if (
            payload[0] instanceof Array
        ) {
            return Promise.all(payload.map(_request))
        }
        return _request(payload)
    }
}
