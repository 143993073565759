import "./instrument";
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import '@ant-design/v5-patch-for-react-19';

import './index.css'
import App from './App.jsx'
import { BrowserRouter } from "react-router";
import { AppProvider } from './providers/AppProvider.jsx'
import { NuqsAdapter } from 'nuqs/adapters/react'

createRoot(document.getElementById('root')).render(
    <BrowserRouter>
      <AppProvider>
        <NuqsAdapter>
          <App />
        </NuqsAdapter>
      </AppProvider>
    </BrowserRouter>
)
