import { DEFAULT_MENU_ITEMS } from "@/constants/menu";
import { useAuth } from "@/hooks";
import { DownOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Menu, Space } from "antd";
import { useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AnlanLogo from './logo.png';
import { useUserRoutes } from "@/hooks/auth/useUserRoutes";
import { SuspenseBoundary } from "@/components/SuspenseBoundary";

const Logo = ({ onClick }) => {
    const logoImage = import.meta.env.VITE_LOGO_DATA || AnlanLogo

    return (
        <div
            className="h-2 m-4 rounded-md text-center flex justify-center items-center cursor-pointer "
            style={{
                background: 'rgba(255,255,255,.2)',
            }}
            onClick={onClick}
        >
            <img
                src={logoImage}
                className='w-14 h-8 transition-all	duration-200'
            />
        </div>
    )
}


export const MainLayout = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { logout, userInfo } = useAuth()
    const currentPath = location.pathname
    const { routers } = useUserRoutes()
    const [selectedKeys, setSelectedKeys] = useState([])
    const [collapsed, setCollapsed] = useState(false)
    const items = routers.map(router => {
        return {
            label: router.router_name,
            key: router.router_name,
            path: router.router_value,
        }
    })
    const handleMenuClick = (event) => {
        const { key } = event
        const target = items.find(menuItem => menuItem.key == key)
        if (target) {
            navigate(target.path)
        }
    }


    const selectedKey = useMemo(() => {
        const firstPath = currentPath.split('/')[1]
        return items.find(item => item.path.includes(firstPath))?.key
    }, [currentPath])


    const handleLogoClick = () => {
    }




    return <div className="min-h-[calc(100vh-62px)] bg-[#f5f5f5]">
        <div className='fixed top-0 left-0 right-0 flex z-[1000] items-center bg-white justify-between shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]'>
            <Logo collapsed={collapsed} onClick={handleLogoClick} />
            <div className='flex items-center'>
                <Menu className="min-w-[300px] " selectedKeys={[selectedKey]} theme="light" mode="horizontal" items={items || []} onClick={handleMenuClick} />
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: 'logout',
                                icon: <LogoutOutlined />,
                                label: '退出登录',
                                onClick: logout,
                            },
                        ],
                    }}
                >
                    <Button type="text" className="mr-4">
                        <Space>
                            <Avatar size="small" icon={<UserOutlined />} />
                            <span>{userInfo.username}</span>
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </div>
        </div>
        <div className="mt-[62px]  bg-[#f5f5f5]">
            <SuspenseBoundary relative>
                <Outlet />
            </SuspenseBoundary>
        </div>
    </div>
};
