import { createElementObject, createLayerComponent, extendContext } from './core/';
// import { LayerGroup as LeafletLayerGroup } from '@anlan/gs-map';
import L from '@anlan/gs-map';
const LeafletLayerGroup = L.LayerGroup;

export const LayerGroup = createLayerComponent(function createLayerGroup({ children: _c , ...options }, ctx) {
    const group = new LeafletLayerGroup([], options);
    return createElementObject(group, extendContext(ctx, {
        layerContainer: group
    }));
});
