import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { SuspenseBoundary } from "./components";
import { MainLayout } from "./components/Layout/MainLayout";
import { SIGN_IN_PATH } from "./constants";
import { useAuth } from "./hooks";
import { lazyImport } from "./utils";
const { SignInPage } = lazyImport(() => import('./pages/SignIn'), 'SignInPage')
const { SignUpPage } = lazyImport(() => import('./pages/SignUp'), 'SignUpPage')
const { VesselInspectionMediaProjectList } = lazyImport(() => import('./pages/VesselInspectionMedia'), 'VesselInspectionMediaProjectList')
const { VesselInspectionMediaProject } = lazyImport(() => import('./pages/VesselInspectionMedia/InspectionProject'), 'VesselInspectionMediaProject')
const { VesselInspectionMediaDetails } = lazyImport(() => import('./pages/VesselInspectionMedia/InspectionProject/mediaDetails'), 'VesselInspectionMediaDetails')
const { VesselInspectionChat } = lazyImport(() => import('./pages/VesselInspectionMedia/Chat'), 'VesselInspectionChat')
const { VicDocumentSearch } = lazyImport(() => import('./pages/VicDocumentSearch'), 'VicDocumentSearch')
const { AppraisalAssistantLayout } = lazyImport(() => import("./pages/AppraisalAssistant/"), "AppraisalAssistantLayout")
const { Conversation } = lazyImport(() => import("./pages/AppraisalAssistant/"), "Conversation")
const { OCR } = lazyImport(() => import("./pages/AppraisalAssistant/"), "OCR")
const { OCRHistory } = lazyImport(() => import("./pages/AppraisalAssistant/"), "OCRHistory")
const { MediaToText } = lazyImport(() => import("./pages/AppraisalAssistant/"), "MediaToText")
const { MediaToTextHistory } = lazyImport(() => import("./pages/AppraisalAssistant/"), "MediaToTextHistory")
const { ConversationLocal } = lazyImport(() => import("./pages/AppraisalAssistant/"), "ConversationLocal")
const AuthGuard = () => {
    const { isLogined } = useAuth()

    if (!isLogined) {
        return <Navigate to={SIGN_IN_PATH} />
    }
    return <SuspenseBoundary>
        <Outlet />
    </SuspenseBoundary>
}

const DefaultLayout = () => {
    return (
        <MainLayout>
            <Outlet />
        </MainLayout>
    )
}

const SignLayoutRoute = () => {
    return (
        <SignLayout>
            <Outlet />
        </SignLayout>
    )
}

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="signin" element={<SignInPage />} />
            <Route path="signup" element={<SignUpPage />} />
            <Route element={<AuthGuard />}>
                <Route element={<DefaultLayout />}>
                    <Route path="*" element={<VesselInspectionMediaProjectList />} />
                    <Route path="/vessel-inspection-media" element={<VesselInspectionMediaProjectList />} />
                    <Route path="/vessel-inspection-media/:id" element={<VesselInspectionMediaProject />} />
                    <Route path="/vessel-inspection-media/:id/:mediaId" element={<VesselInspectionMediaDetails />} />
                    <Route path="/vessel-inspection-media/chat/:id" element={<VesselInspectionChat />}></Route>
                    <Route path="/vic-document-search" element={<VicDocumentSearch />}></Route>
                </Route>
                <Route path="/appraisal-assistant" element={<AppraisalAssistantLayout />} >
                    <Route key="conversation" index element={<Conversation />} />
                    <Route key="conversation-local" path="local-report" element={<ConversationLocal />} />
                    <Route key="ocr" path="ocr" element={<OCR />} />
                    <Route key="ocr-history" path="ocr/history" element={<OCRHistory />} />
                    <Route key="media-to-text" path="media-to-text" element={<MediaToText />} />
                    <Route key="media-to-text-history" path="media-to-text/history" element={<MediaToTextHistory />} />
                </Route>
            </Route>
        </Routes>
    )
}