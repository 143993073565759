import { createElementObject, createPathComponent, extendContext } from './core/';
// import { Rectangle as LeafletRectangle } from '@anlan/gs-map';
import L from '@anlan/gs-map';
const LeafletRectangle = L.Rectangle;

export const Rectangle = createPathComponent(function createRectangle({ bounds , ...options }, ctx) {
    const rectangle = new LeafletRectangle(bounds, options);
    return createElementObject(rectangle, extendContext(ctx, {
        overlayContainer: rectangle
    }));
}, function updateRectangle(layer, props, prevProps) {
    if (props.bounds !== prevProps.bounds) {
        layer.setBounds(props.bounds);
    }
});
