import { useMemoizedFn } from "ahooks";
import { notification } from 'antd';
import { useMemo } from "react";
import { useSuspensedSWR } from "../common/useSuspensedSWR";
import { useFetcher } from "../common/useFetcher";
import { useUserRoutes } from "./useUserRoutes";


export const useAuth = () => {
    const fetcher = useFetcher()
    const { mutate: mutateUserRoutes, role } = useUserRoutes()
    const { data: response, mutate } = useSuspensedSWR("getUserInfo", null, {
        revalidateOnFocus: false,
    })

    const { isLogined, userInfo } = useMemo(() => {
        return {
            isLogined: response?.status === 0,
            userInfo: response?.data
        }
    }, [response])

    const logout = useMemoizedFn(async () => {
        const { data, status } = await fetcher("logout")
        await mutate("getUserInfo")
    })

    const login = useMemoizedFn(async (payload) => {
        const { data, status, msg } = await fetcher(
            [
                'login',
                payload
            ]
        )
        if (status != 0) {
            throw new Error(msg)
        }
        await mutate("getUserInfo")
        await mutateUserRoutes()
        return true
    })


    return {
        isLogined,
        userInfo,
        logout,
        login
    }
}