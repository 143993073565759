import { uniqueId } from "@/utils/uniqueId";
import { MAIN_PAGE_URL } from "./index";
export const DEFAULT_MENU_ITEMS = [
    {
        label: '现场助手',
        key: uniqueId(),
        path: MAIN_PAGE_URL,
    },
    {
        label: "公估检索",
        key: uniqueId(),
        path: "/vic-document-search"
    },
    // {
    //     label: "公估321",
    //     key: uniqueId(),
    //     path: "/appraisal-assistant"
    // }
    // {
    //     label: '运单管理',
    //     key: uniqueId(),
    //     path: '/transport',
    // },
    // {
    //     label: '业务管理',
    //     key: uniqueId(),
    //     path: '/business',
    // },
    // {
    //     label: '企业评分',
    //     key: uniqueId(),
    //     path: '/credit',
    // },
]