import React, { Suspense } from 'react'
import { Loading } from '../Loading/index'
import { cn } from '@/utils'
export const SuspenseBoundary = ({ children, loadingClassName, fallback, relative = false, minHeight = 300 }) => {

  if (relative) {
    return <div className='relative ' style={{ minHeight: minHeight }}>
      <Suspense fallback={fallback || <Loading className={cn(loadingClassName, 'absolute')} />}>{children}</Suspense>
    </div>
  }

  return <Suspense fallback={fallback || <Loading className={loadingClassName} />}>{children}</Suspense>
}
