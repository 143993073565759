/**
 * 本文件集中管理全部接口文件，接口配置格式
 * method: get/post(default get)
 * url:
 * showSuccess:请求成功时是否显示提示（default false）
 * showError:请求失败时是否显示提示（default false）
 * message:请求的提示信息内容 (default '获取数据')
 * data:{} 实际调用接口时直接传入对象
 * params:{} 实际调用接口时直接传入对象
 * responseType: 'arraybuffer', 'blob', 'document', 'json', 'text','stream' 表示服务器响应的数据类型(default
 * json)
 */
// const mockUrl = 'http://api-doc.anlantech.cn/mock/13'
const baseUrl = '/api'
const dataCenter = '/api/datacenter/papi'
const maintainUrl = '/api/maintain'
const userCenterUrl = '/userCenter'



const VicDocumentAPI = {

  uploadAiFile:{
    method: 'post',
    url: baseUrl + '/file/upload/py',
    message: '意简AI文件上传',
  },

  createMediaToTextTask:{
    method: 'post',
    url: baseUrl + '/interview/speech/update',
    message: '意简提交语音oss文件路径给讯飞转文字',
  },
  getMediaToTextRecordList:{
    method: 'get',
    url: baseUrl + '/interview/speech/search',
    message: '意简查询语音转文字任务记录',
  },
  getMediaToTextTaskResult:{
    method: 'get',
    url: baseUrl + '/datacenter/standard/xunfei/audio/transcription/result',
    message: '意简查询语音转文字任务结果',
  },
  updateOCRRecord: {
    method: 'post',
    url: baseUrl + '/interview/ocr/update',
    message: '意简OCR识别记录更新',
  },
  getOCRRecordList: {
    method: 'get',
    url: baseUrl + '/interview/ocr/search',
    message: '意简OCR识别记录查询',
  },
  getVicSearchSuggestion: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/al/vic/pdf/log',
    message: '意简公估文档模糊查询建议',
  },

  searchVicDocument: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/al/vic/pdf/search',
    message: '意简公估文档模糊查询',
  },
}

const TrackingAPI = {
  getZonePoints: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/zones/cx',
    message: '获取特殊区域数据',
  },
}

const V4RiskReportAPI = {
  getRiskReportPdf: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv4/report/pdf/download',
    message: '获取风控报告PDF V4(241118)',
  },
}


const RiskDashboardAPI = {
  /** ***********  Dashboard start    ************ */

  getTyphoonList: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/enviroment/weather/typhoon',
    message: '获取台风详情',
  },

  getManageShipScore: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/mission/insur/risk/counts',
    message: '承保船舶风控数量',
  },

  getManageShipCount: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/mission/insur/type/counts',
    message: '承保船舶类型数量统计',
  },

  getAlertCount: {
    method: 'get',
    url: baseUrl + '/ship/insur/alert/count/all',
    message: '根据预警类型ID分类承保船舶数量',
  },

  getAlertShips: {
    method: 'get',
    url: baseUrl + '/ship/insur/alert/ships/all',
    message: '预警承保船舶信息列表查询',
  },

  getInsuredShipList: {
    method: 'get',
    url: baseUrl + '/ship/mission/insur/ships',
    message: '获取承保船舶列表',
  },

  getEarlyWarningUnion: {
    method: 'get',
    url: baseUrl + '/ship/insur/alert/union',
    message: '看板承保船舶预警并集筛选',
  },

  /** ***********  Dashboard end  ************ */
}

const RedirectAPI = {
  getRedirectToken: {
    url: baseUrl + '/user/token/get',
    method: 'get',
  },
}

const RiskReductionAPI = {

  // /django/alert_message/message

  generateReductionTaskAlertMessage: {
    url: baseUrl + '/datacenter/django/alert_message/message',
    method: 'post',
    message: '风险减量生成推送消息',
  },

  reductionTaskMessageMutation: {
    url: baseUrl + '/datacenter/standard/reduction/message/update',
    method: 'post',
    message: '风险减量保司或船东用户发送任务消息',
  },

  getReductionTaskMessages: {
    url: baseUrl + '/datacenter/standard/reduction/message/search',
    method: 'get',
    message: '风险减量保司或船东用户查询任务对话消息',
  },

  getReductionTaskAlerts: {
    url: baseUrl + '/datacenter/standard/reduction/guarantor/alert/search',
    method: 'get',
    message: '风险减量保司用户查询任务下预警列表',
  },

  getReductionUserTasks: {
    url: baseUrl + '/reduction/insur/missions',
    method: 'get',
    message: '风险减量船东用户任务列表',
  },
  getReductionTasks: {
    url: baseUrl + '/reduction/guarantor/mmsi/search',
    method: 'get',
    message: '风险减量保司用户查询任务',
  },
  reductionTaskAlertMutation: {
    url: baseUrl + '/datacenter/standard/reduction/guarantor/alert/update',
    method: 'post',
    message: '风险减量保司用户提交更新任务下预警列表',
  },
  reductionTaskMutation: {
    url: baseUrl + '/reduction/guarantor/mmsi/update',
    method: 'post',
    message: '风险减量保司用户创建任务',
  },
  getAlertInfo: {
    url: baseUrl + '/datacenter/standard/ship/mission/alert/search',
    method: 'get',
    message: '获取船舶预警信息',
  },
  getCompanyUserShipList: {
    method: 'get',
    url: baseUrl + '/reduction/guarantor/ships',
    message: '获取保司用户风险减量绑定船舶列表',
  },
  getRiskReductionAlertCount: {
    method: 'get',
    url: baseUrl + '/reduction/guarantor/alert/type/count',
    message: '获取风险减量预警类型数量',
  },
  getRiskReductionAlertList: {
    method: 'get',
    url: baseUrl + '/reduction/guarantor/alert/ships/all',
    message: '获取风险减量预警船舶列表',
  },

  getRiskReductionUserShipList: {
    method: 'get',
    url: baseUrl + '/reduction/insur/ships',
    message: '获取风险减量船东用户绑定船舶列表',
  },
  getRiskReductionUserAlertCount: {
    method: 'get',
    url: baseUrl + '/reduction/insur/alert/type/count',
    message: '获取风险减量船东用户预警类型数量',
  },
  getRiskReductionUserAlertList: {
    method: 'get',
    url: baseUrl + '/reduction/insur/alert/ships/all',
    message: '获取风险减量船东用户预警船舶列表',
  },
}

const V5RiskReportAPI = {
  getRiskReportPdfV5:{
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv5/report/pdf/download',
    message: '获取风控报告PDF V5',
  },
  getCargoRiskInfo: {
    method: 'get',
    url: baseUrl + '/datacenter/django/risk_cargo/get',
    message: '获取货物风险信息(V5)',
  },

  getRiskRouteV5: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv5/ship/route',
    message: '获取风控航区信息(V5)',
  },
  getCrewRisk:{
    method: 'get',
    url: baseUrl + '/datacenter/django/crew/risk',
    message: '获取船员风险信息(V5)',
  },
  getHistoryRiskScoreV5: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv5/history',
    message: '获取风控历史分数(V5)',
  },
  // 请求后端计算风控报告
  runReportCalculateV5: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv5/update',
    message: '更新风控报告(V5)',
  },
  queryRiskReportV5: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv5/search',
    message: '查询风控报告(V5)',
  },
  getRiskReportStatusV5: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv5/status',
    message: '获取船舶风控报告状态(V5)',
  },

  // 获取风控报告分数及Reason
  getRiskReportScoreV5: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv5/ship/score',
    message: '获取船舶分数(V5)',
  },
}

const RecommendAPI = {

  getRecommendShipList: {
    method: 'get',
    url: baseUrl + '/recommendation/common/search',
    message: '业务推荐船舶通用查询(2024)',
  },
  unlockRecommendShipScore: {
    method: 'get',
    url: baseUrl + '/recommendation/user/unlock',
    message: '业务推荐船舶评分解锁(2024)',
  },
  downloadUnlockedList: {
    method: 'get',
    url: baseUrl + '/recommendation/user/download',
    message: '业务推荐船舶解锁后下载(2024)',
  },
  getRecommendUnlockShipList: {
    method: 'get',
    url: baseUrl + '/recommendation/user/search',
    message: '业务推荐船舶解锁后查询(2024)',
  },
  getRecommendProvinceCounts: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/recommendation/province/bulletin',
    message: '获取业务推荐省份船舶数量统计(2024)',
  },
  getRecommendShipTypeCounts: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/recommendation/categories/bulletin',
    message: '获取业务推荐船舶类型数量统计(2024)',
  },
  getRecommendShipOtherTypeCounts: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/recommendation/shiptype/list',
    message: '获取业务推荐船舶三方类型数量统计(2024)',
  },



  getRecommendShipTypesOptions: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/recommendation/categories/list',
    message: '获取业务推荐船舶大类列表(2024)',
  },
  getRecommendCityOptions: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/recommendation/city/list',
    message: '获取业务推荐城市列表(2024)',
  },
  getRecommendCompanyOptions: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/recommendation/company/list',
    message: '获取业务推荐公司列表(2024)',
  },
  getRecommendProvinceOptions: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/recommendation/province/list',
    message: '获取业务推荐省份列表(2024)',
  },


}

const ApplicationsAPI = {
  /** ***********  公估工具 start  ************ */

  getVesselInspectionTemplate: {
    method: 'get',
    url: baseUrl + '/interview/record/model/get',
    message: '获取船舶公估检查项目模版详情',
  },
  deleteVesselInspectionTemplate: {
    method: 'get',
    url: baseUrl + '/interview/record/model/del',
    message: '删除船舶公估检查项目模版',
  },
  getVesselInspectionTemplateList: {
    method: 'get',
    url: baseUrl + '/interview/record/model/list',
    message: '获取船舶公估检查项目模版列表',
  },

  updateVesselInspectionTemplate: {
    method: 'post',
    url: baseUrl + '/interview/record/model/update',
    message: '更新船舶公估检查项目模版',
  },

  getVesselInspectionUser:{
    method: 'get',
    url: baseUrl + '/interview/friend/user/search',
    message: '船舶公估联系人查询',
  },

  addVesselInspectioContact:{
    method: 'get',
    url: baseUrl + '/interview/friend/call/add',
    message: '船舶公估联系人新增',
  },
  updateVesselInspectioContact:{
    method: 'get',
    url: baseUrl + '/interview/friend/call/state',
    message: '船舶公估联系人申请审核',
  },

  getVesselInspectioContactRequestList:{
    method: 'get',
    url: baseUrl + '/interview/friend/call/list',
    message: '船舶公估联系人申请列表',
  },

  getVesselInspectioContactList:{
    method: 'get',
    url: baseUrl + '/interview/friend/list',
    message: '船舶公估联系人列表',
  },

  addVesselInspectioContactMessage:{
    method: 'post',
    url: baseUrl + '/interview/friend/message/add',
    message: '船舶公估联系人发送消息',
  },

  getVesselInspectioContactMessageList:{
    method: 'get',
    url: baseUrl + '/interview/friend/message/list',
    message: '船舶公估联系人消息列表',
  },



  getVesselInspectionProjectImageWord: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/interview/record/simple/download',
    message: '获取船舶公估检查项目Word',
  },


  getAliOssStsUploadLink: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/alifile/oss/sts/put',
    message: '获取阿里云OSS上传链接',
  },


  getVesselInspectionProjectList: {
    method: 'get',
    url: baseUrl + '/interview/record/list',
    message: '获取船舶公估检查项目列表',
  },

  mutationInspectProject: {
    method: 'post',
    url: baseUrl + '/interview/record/update',
    message: '新增/修改船舶公估检查项目',
  },

  getVesselInspectionProjectInfo: {
    method: 'get',
    url: baseUrl + '/interview/record/get',
    message: '获取船舶公估检查项目详情',
  },

  deleteVesselInspectionProject: {
    method: 'get',
    url: baseUrl + '/interview/record/del',
    message: '删除船舶公估检查项目',
  },
}
/** ***********  公估工具 end  ************ */




const FleetDashboardAPI = {
  getFleetDashboardStatisticsData: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/picc/count/active',
    message: 'PICC当前承保数量统计',
  },
  getFleetDashboardPolicy: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/picc/policy/search',
    message: 'PICC当前保单查询',
  },
  getFleetDashboardVesselLocation: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/picc/ship/location',
    message: 'PICC当前船舶位置',
  },
}

export default {
  ...VicDocumentAPI,
  ...FleetDashboardAPI,
  ...V4RiskReportAPI,
  ...TrackingAPI,
  ...RiskDashboardAPI,
  ...RedirectAPI,
  ...RiskReductionAPI,
  ...V5RiskReportAPI,
  ...RecommendAPI,
  ...ApplicationsAPI,
  /** ***********  订单相关 start  ************ */

  getVIPOrderList: {
    method: 'get',
    url: baseUrl + '/user/goods/role/order/list',
    message: '用户已购买角色升级记录',
  },
  getAPIOrderList: {
    method: 'get',
    url: baseUrl + '/user/goods/access/order/list',
    message: '用户已购买接口记录',
  },

  getAPIOrderSum: {
    method: 'get',
    url: baseUrl + '/user/goods/access/order/sum',
    message: '查询用户接口权限数量',
  },

  getAPIOrderLog: {
    method: 'get',
    url: baseUrl + '/user/goods/access/order/log',
    message: '查询已使用接口记录',
  },

  refreshVIPStatus: {
    method: 'get',
    url: baseUrl + '/login/fresh',
    message: '刷新角色信息(升级VIP后调用)',
  },
  getVIPGoods: {
    method: 'post',
    url: baseUrl + '/user/goods/role/all',
    message: '获取VIP商品列表',
  },
  getAPIAccess: {
    method: 'post',
    url: baseUrl + '/user/access/valid',
    message: '获取接口状态',
  },

  getOrderStatus: {
    method: 'get',
    url: baseUrl + '/pay/order/get',
    message: '获取订单信息',
  },
  getAPIGoods: {
    method: 'get',
    url: baseUrl + '/user/goods/access/all',
    message: '获取接口列表',
  },

  createVIPOrder: {
    method: 'get',
    url: baseUrl + '/user/goods/role/order/create',
    message: '创建角色商品订单获取订单ID',
  },
  createAPIOrder: {
    method: 'get',
    url: baseUrl + '/user/goods/access/order/create',
    message: '创建接口商品订单获取订单ID',
  },

  getAliPayNativeURL: {
    method: 'get',
    url: baseUrl + '/pay/alipay/native',
    message: '生成支付宝二维码支付地址',
  },

  getWechatPayNativeURL: {
    method: 'get',
    url: baseUrl + '/pay/wechat/native',
    message: '生成微信二维码支付地址',
  },
  /** ***********  订单相关 end  ************ */
  /** ***********  clackson start    ************ */

  // 船舶筛选 clackson
  getClacksonList: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/clackson/list',
    message: 'clackson查询',
  },

  // 通过船队查询名称查询clackson船舶类型
  getClacksonShipTypeList: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/clackson/shipType',
    message: 'clackson船舶类型',
  },

  // 船舶估值 通过imo查询船舶估值需要的船舶信息
  queryShipListByImo: {
    method: 'get',
    url: '/api/datacenter/standard/clackson/evalution/param',
    message: '通过imo查询船舶信息',
  },
  valuationShip: {
    method: 'get',
    url: '/api/datacenter/django/ship_pricing',
    message: '船舶估值',
  },
  valuationShipAndChart: {
    method: 'post',
    url: '/api/datacenter/standard/clackson/evalution/price',
    message: '船舶估值和曲线图',
  },
  queryClacksonShip: {
    method: 'post',
    url: '/api/datacenter/standard/clackson/search',
    message: '通过mmsi,船舶名查询船舶',
  },
  valuationShipType: {
    method: 'get',
    url: '/api/datacenter/standard/clackson/types',
    message: '估值船舶类型',
  },
  blurryQueryVauationShip: {
    method: 'get',
    url: '/api/datacenter/django/shipsearch',
    message: '估值船舶模糊查询',
  },
  /** ***********  clackson end    ************ */

  /** ***********  账号相关 start  ************ */

  getInvitees: {
    method: 'get',
    url: baseUrl + '/user/recommend/count',
    message: '获取当前邀请人数信息',
  },
  setRecommend: {
    method: 'get',
    url: baseUrl + '/user/recommend',
    message: '绑定推荐码',
  },

  postFeedback: {
    method: 'post',
    url: baseUrl + '/user/feedback/update',
    message: '提交反馈信息',
  },

  getUserMissionCount: {
    method: 'get',
    url: baseUrl + '/ship/mission/count',
    message: '获取用户可关注船舶数量',
  },

  comments: {
    method: 'post',
    url: baseUrl + '/comments/contact',
    message: '留言',
  },
  // 登录
  login: {
    method: 'post',
    url: baseUrl + '/login',
    showError: true,
    message: '登录',
  },

  // 手机号码登录
  mobileLogin: {
    method: 'post',
    url: baseUrl + '/login/phone',
    showError: true,
    message: '登录',
  },

  getUserAuthority: {
    method: 'get',
    url: baseUrl + '/privileges',
    message: '获取用户权限',
  },
  getVerificationCode: {
    method: 'post',
    url: baseUrl + '/phone/regist/verification',
    showError: true,
    message: '手机号注册验证码',
  },

  registeredUserByMobile: {
    method: 'post',
    url: baseUrl + '/phone/regist/submit',
    showError: true,
    message: '通过手机号注册用户',
  },

  resetPasswordVerificationCode: {
    method: 'post',
    url: baseUrl + '/phone/password/verification',
    showError: true,
    message: '通过手机验证码重置密码',
  },

  resetPasswordByMobile: {
    method: 'post',
    url: baseUrl + '/phone/password/submit',
    showError: true,
    message: '通过手机号重置密码',
  },

  // 退出登录
  logout: {
    method: 'post',
    url: baseUrl + '/logout',
    message: '退出登录',
  },

  // 设置新密码
  setNewPassword: {
    method: 'post',
    url: baseUrl + '/handleForgetPwd',
    message: '设置新密码',
    showError: true,
    showSuccess: true,
  },

  // 发送重置密码邮件
  forgetPassword: {
    method: 'post',
    url: baseUrl + '/submitForgetPwd',
    message: '发送重置密码邮件',
    showError: true,
    showSuccess: true,
  },

  // 重置密码 old
  resetPassword: {
    method: 'post',
    url: baseUrl + '/resetPassword',
    showError: true,
    message: '重置密码',
  },

  // 重置密码 new
  nResetPassword: {
    method: 'post',
    url: baseUrl + '/user/password',
    showError: true,
    message: '重置密码',
  },

  // 获取绑定手机验证码
  bindPhoneVerificationCode: {
    method: 'post',
    url: baseUrl + '/user/phone/bind/verification',
    showError: true,
    message: '获取绑定手机验证码',
  },

  // 修改账号绑定手机号码
  submitBindPhone: {
    method: 'post',
    url: baseUrl + '/user/phone/bind/submit',
    showError: true,
    message: '绑定手机确认提交',
  },

  getUserInfo: {
    method: 'get',
    url: baseUrl + '/user/get',
    message: '获取用户信息',
  },

  getFavoriteGroup: {
    method: 'get',
    url: baseUrl + '/ship/mission/cates',
    message: '获取关注列表分组',
  },

  // http://api-doc.anlantech.cn/project/13/interface/api/321
  getFavorite: {
    method: 'get',
    url: baseUrl + '/ship/mission/search',
    message: '获取用户关注船舶信息',
  },
  // http://api-doc.anlantech.cn/project/13/interface/api/319
  addUserFollow: {
    method: 'post',
    url: baseUrl + '/ship/mission/add',
    message: '添加用户关注船舶信息',
  },
  removeUserFollow: {
    method: 'post',
    url: baseUrl + '/ship/mission/delete',
    message: '移除用户关注船舶信息',
  },
  updateFavoriteCate: {
    method: 'get',
    url: baseUrl + '/ship/mission/cate/update',
    message: '修改分组列表',
  },

  /** ***********  账号相关 end    ************ */

  /**************** 风控报告start */

  getIncidentByMMSI: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/incident/mmsi',
    message: '根据mmsi查询通告预警',
  },

  getMortgage: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/mortgage',
    message: '查询船舶抵押详情',
  },

  getCompanyScoreAndInfo: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/company/score',
    message: '船舶公司评分及信息',
  },

  getPaperwork: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/wenshu/mmsi',
    message: '根据船舶mmsi查询文书',
  },

  getShipAndCompanyInfo: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/msa/all',
    message: '船舶和公司重点跟踪及信任和证书综合查询',
  },

  getVoyageSegment: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv4/distance/count',
    message: '获取航速分段信息',
  },

  // getVoyageSegmentCopy: {
  //   method: 'get',
  //   url: baseUrl + '/datacenter/standard/riskv4/distance/copy',
  //   message: '获取航速分段信息Copy',
  // },

  getVoyageSegmentV5: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv5/distance/count',
    message: '获取航速分段信息',
  },

  getVoyageSegmentCopy: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv5/distance/copy',
    message: '获取航速分段信息Copy',
  },

  getPlatformTaskCount: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/elevating/overlap/worked',
    message: '获取平台作业次数',
  },

  getRiskReportImage: {
    responseType: 'blob',
    method: 'get',
    timeout: 1000 * 30,
    url: baseUrl + '/datacenter/standard/riskv4/report/download',
    message: '风控报告文件',
  },
  // 仅在风控使用
  // getManagerCredit: {
  //   method: 'get',
  //   url: baseUrl + '/datacenter/standard/credit/manager',
  //   message: '获取船舶违规列表',
  // },

  getManagerViolationList: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/credit/manager/ship',
    message: '获取船舶管理公司旗下船舶违规情况',
  },

  getRiskReportStatus: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv4/status',
    message: '获取船舶风控报告状态',
  },

  // 获取风控报告分数及Reason
  getRiskReportScore: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv4/ship/score',
    message: '获取船舶分数',
  },

  getRiskReport: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/risk',
    showError: true,
    timeout: 1000 * 60,
    message: '获取船舶风控报告',
  },

  getCompanyInfo: {
    method: 'get',
    timeout: 1000 * 15,
    url: baseUrl + '/datacenter/sapi/companyInfo',
    message: '获取船舶管理公司信息',
  },
  /** ***********  船舶档案 Document 页 start  ************ */

  getClacksonDocumentChangeset: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/al/anlanimo/changes',
    message: '获取船舶详细字段变更记录',
  },

  getClacksonDocumentV2: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/clackson/imo/v2',
    message: '获取船舶详细档案V2(23/08/15)',
  },

  getClacksonDocument: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/clackson/imo',
    message: '获取船舶详细档案',
  },

  getCNDocument: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/cy/cn',
    message: '获取船舶CN详细档案',
  },

  /** ***********  船舶档案 Document 页 end  ************ */

  /** ***********  总览 Overview 页 start  ************ */
  getIncidentOrgs: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/incident/orgs',
    message: '获取通告预警Org列表',
  },

  getIncidentTag: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/incident/tags',
    message: '获取通告预警标签列表及ID',
  },

  getIncident: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/incidentInfo',
    message: '获取通告预警',
  },

  getIncidentCount: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/incident/count',
    message: '获取通告预警数量',
  },

  // http://api-doc.anlantech.cn/project/18/interface/api/171 违规船舶类型对照表
  getViolationShipType: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/shiptype',
    message: '获取船舶违规类型对照表',
  },

  getIndustryNewsTypes: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/news/column',
    message: '获取行业动态新闻类型',
  },

  // 获取船舶违规详情
  getViolationDetail: {
    method: 'get',
    url: baseUrl + '/penalty/info',
    message: '获取船舶违规详情',
  },

  getViolationList: {
    method: 'get',
    url: baseUrl + '/penalty/lists',
    message: '获取船舶违规列表',
  },

  // 左上角船舶统计数据
  getShipTypeStat: {
    method: 'get',
    url: baseUrl + '/overview/getShipTypeStat',
    showError: true,
    message: '获取船舶统计数据',
  },

  // 左上角行业动态列表
  getIndustryNewsList: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/news/list',
    message: '获取行业动态',
  },

  // 左上角行业动态信息
  getIndustryNewsDetails: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/news/info',
    message: '获取行业动态详情',
  },

  // 左上角保险统计数据
  getInsuranceTypeStat: {
    method: 'get',
    url: baseUrl + '/overview/getPaInsurStat',
    showError: true,
    message: '获取保险统计数据',
  },

  // 获取通告预警信息
  getNotification: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/incidentInfo',
    showError: false,
    message: '获取通告预警信息',
  },

  // 获取平安船舶停靠港口状态
  getPinganShipPortStat: {
    method: 'get',
    url: baseUrl + '/getPinganShipPortStat',
    showError: true,
    message: '获取平安船舶停靠港口状态',
  },

  // 总览页用户预警查询
  getUserEarlyQuery: {
    method: 'get',
    url: baseUrl + '/ship/mission/index',
    showError: true,
    message: '总览页用户预警查询',
  },

  // 总览页承保船舶预警查询
  getEarlyWarningList: {
    method: 'get',
    url: baseUrl + '/ship/insur/alert/index',
    message: '总览页承保船舶预警查询',
  },

  /** ***********  总览 Overview 页 end    ************ */

  /** ***********  业务分析页 start  ************ */

  // 获取船舶基本属性
  getShipAttrData: {
    method: 'get',
    url: baseUrl + '/getShipAttrData',
    showError: true,
    message: '获取船舶基本属性',
  },

  // 获取保单列表
  getInsurList: {
    method: 'post',
    url: baseUrl + '/getInsurList',
    message: '获取保单列表',
  },

  // 获取船舶行为数据
  getShipActionDetail: {
    method: 'get',
    url: baseUrl + '/getShipActionDetail',
    message: '获取船舶行为数据',
  },

  // 获取船舶理赔数据
  getClaim: {
    method: 'get',
    url: baseUrl + '/getClaim',
    message: '获取船舶理赔数据',
  },

  // 获取船舶运营评级数据
  getShipOperateData: {
    method: 'get',
    url: baseUrl + '/getShipOperateData',
    message: '获取船舶运营评级数据',
  },

  // 获取船舶外部风险评级数据
  getShipOuterRisk: {
    method: 'get',
    url: baseUrl + '/getShipOuterRisk',
    message: '获取船舶外部风险评级数据',
  },

  // 获取船舶行为分析数据
  getShipActionScore: {
    method: 'get',
    url: baseUrl + '/getShipActionScore',
    message: '获取船舶行为分析数据',
  },

  /** ***********  业务分析页 end    ************ */

  /** ***********  认领拜访 start    ************ */

  addAssign: {
    method: 'post',
    url: baseUrl + '/ship/claim/update',
    message: '新增修改认领',
  },

  addInsure: {
    method: 'post',
    url: baseUrl + '/ship/deal/update',
    message: '新增出单',
  },

  addVisit: {
    method: 'post',
    url: baseUrl + '/ship/visited/update',
    message: '新增修改拜访',
  },

  getVisitList: {
    method: 'get',
    url: baseUrl + '/ship/visited/search',
    message: '查询船舶拜访记录列表',
  },

  cancelAssign: {
    method: 'get',
    url: baseUrl + '/ship/claim/past/mmsi',
    message: '取消认领',
  },

  getAssignList: {
    method: 'get',
    url: baseUrl + '/ship/claim/search/all',
    message: '查询认领列表',
  },

  getAssignStatus: {
    method: 'get',
    url: baseUrl + '/ship/claim/mmsi',
    message: '认领状态查询',
  },

  /** ***********  认领拜访 end    ************ */

  /** ***********  港口服务 start    ************ */

  getPortTags: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/zyh/port/types',
    message: '获取港口标签',
  },

  getPortAnalyzeData: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/zyh/port/ship/bulletin',
    message: '获取港口分析数据',
  },
  getCoscoPortList: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/zyh/port/search',
    message: '获取COSCO港口列表',
  },

  /** ***********  港口服务 end    ************ */

  /** ***********  航线计算页 start    ************ */

  getPortRoute: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/port/pathfind',
    message: '获取港口航线',
    timeout: 1000 * 60,
  },

  /** ***********  航线计算页 end    ************ */

  /** ***********  在线投保页 start  ************ */

  // 获取货运险运费选项
  getCargoPortSelectOptions: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/port/destination',
    message: '获取货运险运费选项',
  },

  // 计算货物费率
  getCargoFeeEstimate: {
    method: 'get',
    url: baseUrl + '/datacenter/django/cargo_price',
    message: '计算货物费率',
  },

  // 搜索货物类型
  searchCargoType: {
    method: 'get',
    url: baseUrl + '/datacenter/django/cargo_type',
    message: '训练货物类型',
  },
  // 获取货物类型
  getCargoType: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/port/cargotype',
    message: '获取货物类型',
  },

  getInsureSelectOptions: {
    method: 'get',
    url: baseUrl + '/datacenter/django/fee/estimate/selector',
    message: '获取费率选项',
  },

  getRateComparisonInsureList: {
    method: 'get',
    url: baseUrl + '/calculation/type',
    message: '获取费率对比类型',
  },

  // 根据关键词获取船舶列表
  // getShipListByKeyword: {
  //   method: 'get',
  //   url: baseUrl + '/datacenter/standard/ship/search',
  //   message: '关键词搜索船舶',
  // },

  // 根据MMSI获取船舶详细信息
  getShipDetail: {
    method: 'post',
    url: dataCenter + '/ship/detail',
    message: '获取船舶详情',
  },

  // 保费测算
  getFeeEstimate: {
    method: 'post',
    // url: baseUrl + '/papi/fee/estimate',
    url: baseUrl + '/datacenter/django/fee/estimate/v2',
    message: '获取保费测算结果',
  },

  getRate: {
    method: 'get',
    url: baseUrl + '/calculation/rate',
    message: '获取投保费率',
  },

  getRateV2: {
    method: 'get',
    url: baseUrl + '/calculation/risk',
    message: '获取投保费率',
  },

  // 保存保单/投保
  submitInsure: {
    method: 'post',
    url: baseUrl + '/insure/submit',
    message: '投保/保存保单',
  },

  // 获取已保存保单
  getInsureDetail: {
    method: 'get',
    url: baseUrl + '/insure/detail',
    message: '获取询价单详情',
  },

  // 获取已保存保单
  getInsureList: {
    method: 'get',
    url: baseUrl + '/insure/list',
    message: '获取投保记录',
  },

  // 获取船舶的平安分类列表
  // getShipTypeList: {
  //   method: 'get',
  //   url: baseUrl + '/ship/type',
  //   message: '获取船舶的平安分类列表',
  // },

  getAccidentRecord: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/imoIncident/search',
    message: '获取船舶事故记录',
  },

  /** ***********  在线投保页 end    ************ */

  /** ***********  赔案助理页 start  ************ */

  getShipList: {
    url: maintainUrl + '/ship/repair/ship_list',
    message: '获取船舶列表',
  },

  getShipRepairGuide: {
    url: maintainUrl + '/ship/repair/guideline',
    message: '获取船舶修理价格指导',
  },

  getShipInsureRecord: {
    url: baseUrl + '/insure/record',
    message: '获取船舶投保记录',
  },

  getRepairCatalog: {
    url: maintainUrl + '/ship/repair/catalog',
    message: '获取船舶修理目录',
  },

  getShipYardList: {
    url: maintainUrl + '/shipyard/list',
    message: '获取船厂列表',
  },

  getShipRepairOrderList: {
    url: maintainUrl + '/ship/claim/order/list',
    message: '获取询价记录',
  },

  getShipRepairOrderDetail: {
    url: maintainUrl + '/ship/repair/order/info',
    message: '获取修理询价单详情',
  },

  getShipRepairOrderBidList: {
    url: maintainUrl + '/ship/repair/enquiry_list',
    message: '获取修理询价单报价总价对比',
  },

  getShipRepairOrderBidDetail: {
    url: maintainUrl + '/ship/repair/enquiry/detail_list',
    message: '获取修理询价单报价对比详情',
  },

  selectShipRepairOrderBid: {
    method: 'post',
    url: maintainUrl + '/ship/repair/send_owner',
    message: '发送筛选出的船厂报价',
  },

  getOrderInfoForShipyard: {
    url: maintainUrl + '/ship/repair/enquiry_info',
    message: '获取询价单信息',
  },

  getShipDetailForShipyard: {
    url: baseUrl + '/insure/ship/detail',
    message: '获取船舶基本信息',
  },

  saveShipRepairOrder: {
    method: 'post',
    url: maintainUrl + '/ship/repair/order',
    message: '保存船舶修理询价单',
  },

  uploadShipRepairOrder: {
    method: 'post',
    url: maintainUrl + '/ship/repair/enquiry_upload',
    message: '船厂上传报价单',
  },

  downloadShipRepairOrder: {
    method: 'post',
    responseType: 'blob',
    url: maintainUrl + '/ship/repair/enquiry_download',
    message: '船厂下载报价单',
  },

  shipyardSubmitShipRepairOrder: {
    method: 'post',
    url: maintainUrl + '/ship/repair/enquiry_save',
    message: '船厂提交/保存',
  },

  submitShipRepairOrder: {
    method: 'post',
    url: maintainUrl + '/ship/repair/send_order',
    message: '提交船舶修理询价单',
  },

  // 获取案例检索选项
  getCaseSearchOptions: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/wenshu/getlist',
  },

  getCaseSearchColumn: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/wenshu/searchColumn',
    message: '字段列表',
  },

  // 获取案例
  getCase: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/wenshu/search',
    message: '查询案例',
  },

  getCaseTagList: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/wenshu/matchwords',
    message: '查询案例标签列表',
  },

  getCaseDetails: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/wenshu/info',
    message: '查询案例详情',
  },

  caseDownload: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/wenshu/download',
    message: '案例下载',
    responseType: 'blob',
  },
  // 获取一港两师律所列表
  getLawFirmList: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/portlaw/firm/search',
    message: '获取一港两师律所列表',
  },
  // 查询一港两师律所列表
  searchLawFirm: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/portlaw/firm/all',
    message: '查询一港两师律所',
  },

  /** ***********  赔案助理页 end    ************ */

  /** ***********  批量风控计算页 start  ************ */

  createBatchTask: {
    method: 'post',
    url: baseUrl + '/risk/batch/create',
    message: '创建批量风控任务',
  },
  getBatchTaskStatus: {
    method: 'get',
    url: baseUrl + '/risk/batch/status',
    message: '批量风控状态查询',
  },
  getBatchTask: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv4/batch/task',
    message: '获取Task详情',
  },
  deleteBatchTask: {
    method: 'get',
    url: baseUrl + '/risk/batch/delete',
    message: '删除批量风控任务',
  },
  getUserTasks: {
    method: 'get',
    url: baseUrl + '/risk/batch/status/all',
    message: '批量查询Task状态',
  },

  /** ***********  批量风控计算页 end  ************ */

  /** ***********  公估报告页 start  ************ */

  getEstimateFile: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/alifile/estimate/download',
    message: '下载公估事故文件',
  },

  getEstimateFiles: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/alifile/estimate/files',
    message: '获取公估事故文件列表',
  },

  getAppraisalTypeOptions: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/estimate/label/distinct',
    message: '获取公估事故类型列表',
  },

  getAppraisalBuilders: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/builder/score/latlon',
    message: '根据经纬度查船厂及评分和最近的港口',
  },

  getAppraisalTugs: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/tug/nearby/search',
    message: '按经纬度查附近拖轮',
  },

  getAppraisalItems: {
    method: 'get',
    url: baseUrl + '/datacenter/django/estimate/risk',
    message: '公估估损报告',
  },

  /** ***********  公估报告页 end  ************ */

  /** ***********  船舶动态页 start  ************ */

  getDeparturePortRecords: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/port/bc/history',
    message: '历史到离港近一个月记录',
  },

  getCompanyShipTypes: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/same/company/type/count',
    message: '同公司船舶类型数量查询',
  },

  getShipDataModelType: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/info/modeltype',
    showError: true,
    timeout: 1000 * 20,
    message: '获取指定船舶估值参数',
  },
  getShipValuationV2: {
    method: 'post',
    url: baseUrl + '/datacenter/django/valuation/run',
    message: '获取船舶估值V2(24.01)',
  },

  getComplianceInfo: {
    method: 'post',
    url: baseUrl + '/datacenter/django/sdn/info',
    message: '获取船舶合规信息',
  },

  getComplianceInfoV2:{
    method: 'post',
    url: baseUrl + '/datacenter/django/sdn/saction',
    message: '获取船舶合规信息V2(24.12.13)',
  },

  getComplianceInfoV3:{
    method: 'post',
    url: baseUrl + '/datacenter/django/sdn/general',
    message: '获取船舶合规信息V3(24.12.27)',
  },

  getSisterShips: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/sisters/mmsi/info',
    message: '姐妹船详情查询',
  },

  getFisherAreaData: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/fisher/area',
    message: '获取渔船密集区域数据',
  },

  getShipValuation: {
    method: 'get',
    url: baseUrl + '/datacenter/django/evaluation_trading',
    message: '获取船舶估值',
  },

  getImoImageList: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/img/imo',
    message: '获取船舶图片列表',
  },

  getIceZoneData: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/zones/ice',
    message: '获取冰区数据',
  },

  getPirateData: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/imoIncident/piracy',
    message: '获取海盗数据',
  },

  getBridgeList: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/bridges/all',
    message: ' 获取桥梁信息列表',
  },

  // 获取承保船舶预警位置
  getEarlyWarningLocation: {
    method: 'get',
    url: baseUrl + '/ship/insur/alert/location',
    message: '承保船舶预警位置查询',
  },

  getEarlyWarningXlsx: {
    method: 'get',
    url: baseUrl + '/ship/mission/insur/alert/download',
    message: '获取承保船舶预警Xlsx文件',
  },

  // 承保船舶预警数量查询
  getEarlyWarningCount: {
    method: 'get',
    url: baseUrl + '/ship/insur/alert/count',
    message: '承保船舶预警数量查询',
  },

  getRiskSpeedAnalyze: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/speed/risk',
    message: '获取风控航速分析结果',
  },
  getRiskSpeedAnalyzeV4: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/speed/risk/v4',
    message: '获取风控航速分析结果(v4)',
  },


  getRiskRoute: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv4/ship/route',
    message: '获取风控航区信息',
  },

  getHistoryRiskScore: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv4/history',
    message: '获取风控历史分数',
  },

  getSpeedAnalyze: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/speed/sum/mmsi',
    message: '获取航速分析结果',
  },

  getSameShips: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/same/name/mmsi',
    message: '相似船舶查询',
  },

  getMinimumSafety: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/msa/crew',
    message: '获取船舶注册信息列表',
    timeout: 1000 * 30,
  }, 

  getCrewMemberList: {
    method: 'get',
    url: baseUrl + '/datacenter/django/crew/onboard',
    message: '获取船舶船员列表',
  },

  getMinimumSafetyDetail: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/msa/crew/info',
    message: '获取船舶注册详情',
  },

  getMonitorChannelList: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/sswgsz/video/list',
    message: '根据mmsi获取视频频道ID列表',
  },

  getMonitorChannelUrlById: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/sswgsz/video/address',
    message: '根据视频频道ID获取视频地址',
  },

  // 船厂查询，用于船卡底部船厂评级
  getBuilderInfo: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/builder/info',
    message: '船厂模糊查询',
  },

  // 获取事故图层列表
  getAccidentList: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/incident/list',
    message: '获取事故图层列表',
  },

  // 获取事故数据详情
  getAccidentDetail: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/incident/detail',
    message: '获取事故数据详情',
  },

  // 获取港口拖轮列表
  getTugboatData: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/tug/port/tug',
    message: '获取港口拖轮列表',
  },

  // 获取港口天气
  getPortWeather: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/enviroment/weather/msn',
    message: '获取港口天气',
  },

  // 模糊查询港口
  searchPort: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/tug/port/search',
    message: '港口查询',
  },
  // 多方查找船舶
  multipleSearchShip: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/shipcheck/json',
    message: '船舶验证',
  },

  // 根据公司名称查询船舶
  getCompanyShips: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/same/company',
    message: '查询公司名下所有船舶',
  },

  getShipsLasttime: {
    method: 'post',
    url: baseUrl + '/datacenter/standard/ship/lasttime',
    message: '获取船舶更新时间',
  },

  unFollow: {
    method: 'DELETE',
    url: baseUrl + '/followShip/delete',
  },
  getFollowList: {
    method: 'get',
    url: baseUrl + '/followShip/get',
  },

  follow: {
    method: 'post',
    url: baseUrl + '/followShip/submit',
  },



  // 请求后端计算风控报告
  runReportCalculate: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv4/update',
    message: '更新风控报告',
  },

  queryRiskReport: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/riskv4/search',
    message: '查询风控报告',
  },

  getHeatMap: {
    method: 'get',
    url: baseUrl + '/datacenter/django/ais',
    showError: true,
    timeout: 1000 * 30,
    message: '获取港口信息',
  },

  getPorts: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/port/portfs',
    showError: false,
    message: '获取港口信息',
  },

  getPortfnh: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/port/portfnh',
    showError: false,
    message: '获取港口信息fns',
  },
  // 获取船舶保赔信息
  getShipOwnersClub: {
    method: 'get',
    url: baseUrl + '/datacenter/sapi/club',
    showError: false,
    message: '获取船舶保赔信息',
  },

  // 获取违规船舶统计信息
  getPenaltyStat: {
    method: 'get',
    url: baseUrl + '/penalty/stats',
    showError: true,
    message: '获取违规船舶统计信息',
  },

  // 船舶搜索模糊查询
  searchShip: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/search',
    showError: true,
    message: '搜索船舶',
  },

  // 获取船舶基本信息 - 新版 by 黄俊翔 2020/8/12
  // getShipData: {
  //   method: 'get',
  //   url: baseUrl + '/ship/info',
  //   showError: true,
  //   timeout: 1000 * 20,
  //   message: '获取指定船舶数据',
  // },

  getShipData: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/info/all',
    showError: true,
    timeout: 1000 * 20,
    message: '获取指定船舶数据',
  },

  // 根据 imo 查 psc 信息
  getPscByImo: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/al/psc/search',
    message: '获取PSC信息',
  },

  // 获取全部平安船舶
  getAllPinganShip: {
    method: 'get',
    url: baseUrl + '/getAllPinganShip',
    showError: false,
    message: '获取全部平安船舶',
  },

  // 获取全部平安渔船
  getAllPinganFishingBoat: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/fisher/getLatestFisherData',
    showError: false,
    message: '获取全部平安渔船',
  },

  // 获取指定区域内船舶
  getAreaShip: {
    method: 'get',
    url: baseUrl + '/ship/area',
    showError: false,
    message: '获取指定区域内船舶',
  },

  // 获取航区信息
  getSailArea: {
    method: 'get',
    url: baseUrl + '/getSailArea',
    showError: false,
    message: '获取航区信息',
  },

  // 记录船舶搜索记录
  addShipSearchHistory: {
    method: 'post',
    url: baseUrl + '/addShipSearchHistory',
    message: '记录船舶搜索记录',
  },

  // 获取船舶历史轨迹数据
  getShipTrack: {
    method: 'get',
    url: '/api/datacenter/sapi/ShipVeselTrack',
    timeout: 20000,
    message: '获取船舶历史轨迹数据',
  },

  // 获取船舶历史轨迹数据并触发挂靠港计算
  getShipTrackV2: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/portdata/vesel',
    timeout: 20000,
    message: '获取船舶历史轨迹数据',
  },

  // 获取船舶历史轨迹数据并触发挂靠港计算
  getShipTrackPort: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/ship/portdata/port',
    timeout: 20000,
    message: '获取船舶挂靠港数据',
  },
  getWindBoundsData: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/enviroment/weather/wind/json/v4',
    message: '获取区域风力数据',
  },

  getWaveBoundsData: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/enviroment/weather/wave/json/v4',
    message: '获取区域海浪数据',
  },

  getWaveUnix: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/enviroment/weather/wave/unix/v4',
    message: '获取海浪数据Unix',
  },

  getWindData: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/alifile/json',
    message: '获取风力数据',
  },
  getWindDate: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/enviroment/weather/wind/date',
    message: '获取风力数据时间',
  },
  getWindDataFilePath: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/enviroment/weather/wind/file',
    message: '获取风力数据文件名',
  },

  calculateShipRiskControlReport: {
    method: 'get',
    url: baseUrl + '/datacenter/risk',
    message: '计算船舶风控报告',
  },

  //获取船舶风控评级 shipinfo底部面板两个字段
  getCreditFinal: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/credit/final_v2',
    message: '计算船舶风控报告',
  },

  //获取船舶风控评级 v3 版
  getCreditFinalV3: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/credit/final_v3',
    message: '获取船舶风控评级',
  },

  // 获取海事局区域范围
  getMaritimeBureauArea: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/zones/marine',
    message: '获取海事局区域',
  },

  // 获取区域预警船舶列表
  getZoneWarnShipList: {
    method: 'get',
    url: baseUrl + '/pgc/getFollowData',
    message: '获取区域预警船舶列表',
  },

  // 获取预警区域列表
  getWarnZoneList: {
    method: 'get',
    url: baseUrl + '/pgc/getRestrictZone',
    message: '获取预警区域列表',
  },

  // 添加预警区域
  addWarnZone: {
    method: 'post',
    url: baseUrl + '/pgc/addRestrictZone',
    showError: true,
    showSuccess: true,
    message: '添加预警区域',
  },

  // 修改预警区域
  updateWarnZone: {
    method: 'post',
    url: baseUrl + '/pgc/updateRestrictZone',
    showError: true,
    showSuccess: true,
    message: '更新预警区域',
  },

  // 删除预警区域
  deleteWarnZone: {
    method: 'post',
    url: baseUrl + '/pgc/delRestrictZone',
    showError: true,
    showSuccess: true,
    message: '删除预警区域',
  },

  // 获取多艘船舶共有范围
  getShipPosition: {
    method: 'post',
    url: baseUrl + '/getShipPosition',
    showError: false,
    message: '获取受台风影响船舶范围',
  },

  // 获取受台风影响船舶数据
  getTyphoonWarnShipList: {
    method: 'get',
    url: baseUrl + '/getRiskEstimate',
    showError: false,
    message: '获取受台风影响船舶数据',
  },

  // 获取台风详情
  getTyphoonDetails: {
    method: 'get',
    url: baseUrl + '/typhoonDetails',
    showError: true,
    message: '获取台风详情',
  },

  // 获取违规预警信息
  getPenaltyInfo: {
    method: 'get',
    url: baseUrl + '/penaltyInfo',
    showError: false,
    message: '获取违规预警信息',
  },

  // 获取超出航区的船舶列表
  getBeyondDepartureAreaShipList: {
    method: 'get',
    timeout: 1000 * 60,
    url: baseUrl + '/beyondDepartureAreaShips/MmsiList',
    showError: true,
    message: '获取超出航区船舶列表',
  },

  // 获取超出航区的船舶轨迹详情
  getBeyondDepartureAreaShipDetail: {
    method: 'get',
    timeout: 1000 * 30,
    url: baseUrl + '/beyondDepartureAreaShips/History',
    showError: true,
    message: '获取超出航区船舶轨迹',
  },

  getPlatformTypeOptions: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/elevating/platform/types',
    message: '获取平台类型',
  },

  queryPlatforms: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/elevating/platform/search',
    message: '平台列表查询',
  },

  batchQueryShipByMmsi: {
    method: 'post',
    url: baseUrl + '/datacenter/standard/ship/info/multi',
    message: '通过mmsi批量查询船舶信息',
  },

  getPlatformOverlap: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/elevating/platform/overlap',
    message: '平台周边船舶查询',
  },
  getPlatformOverlapShips: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/elevating/overlap/search',
    message: '平台船舶动态次数查询',
  },
  getPlatformIncident: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/incident/platform/list',
    message: '平台相关通告预警查询',
  },

  uploadFile: {
    method: 'post',
    url: baseUrl + '/file/upload',
    message: '多文件上传',
  },

  /** ***********  船舶动态页 end    ************ */

  /** ***********  机构 start    ************ */
  getAgencyList: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/mechanism/search',
    message: '机构列表查询',
  },

  getAgencyInfo: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/mechanism/detail',
    message: '机构详情',
  },

  getAgencyOptions: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/mechanism/selectors',
    message: '获取机构过滤选项',
  },

  getMaintenanceOrganizationList: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/mechanism/business',
    message: '获取检修机构列表',
  },

  /** ***********  机构 end  ************ */

  /** ***********  用户管理页 start  ************ */

  uploadAvatar: {
    method: 'post',
    url: baseUrl + '/user/avatar/upload',
    message: '上传用户头像',
  },

  // 获取用户列表
  getUserList: {
    method: 'get',
    url: baseUrl + '/user/list',
    message: '获取用户列表',
    showError: true,
  },

  // 添加用户
  addUser: {
    method: 'post',
    url: baseUrl + '/user/add',
    showError: true,
    showSuccess: true,
    message: '添加用户',
  },

  // 修改用户信息 old
  updateUser: {
    method: 'put',
    url: baseUrl + '/user/update',
    showError: true,
    showSuccess: true,
    message: '修改用户信息',
  },

  // 修改用户信息 new
  nUpdateUser: {
    method: 'post',
    url: baseUrl + '/user/update',
    showError: true,
    showSuccess: true,
    message: '修改用户信息',
  },

  // 删除用户信息
  deleteUser: {
    method: 'delete',
    url: baseUrl + '/user/delete',
    showError: true,
    showSuccess: true,
    message: '删除用户信息',
  },

  // 获取用户权限
  getAuthorities: {
    method: 'get',
    url: baseUrl + '/user/authority/list',
    showError: true,
    showSuccess: false,
    message: '获取权限列表',
  },

  // 获取用户登录历史记录
  getUserLoginHistory: {
    method: 'post',
    url: baseUrl + '/getUserLoginHistory',
    showError: true,
    message: '获取用户登录历史记录',
  },

  // 获取全部权限列表
  getAuthority: {
    method: 'get',
    url: baseUrl + '/authority/list',
    showError: true,
    message: '获取权限列表',
  },

  addAuthority: {
    method: 'post',
    url: baseUrl + '/authority/add',
    showError: true,
    showSuccess: true,
    message: '添加权限',
  },

  updateAuthority: {
    method: 'put',
    url: baseUrl + '/authority/update',
    showError: true,
    showSuccess: true,
    message: '修改权限',
  },

  delAuthority: {
    method: 'delete',
    url: baseUrl + '/authority/delete',
    showError: true,
    showSuccess: true,
    message: '删除权限',
  },

  getRole: {
    method: 'get',
    url: baseUrl + '/role/list',
    showError: true,
    message: '获取角色列表',
  },

  addRole: {
    method: 'post',
    url: baseUrl + '/role/add',
    showError: true,
    showSuccess: true,
    message: '添加角色',
  },

  updateRole: {
    method: 'put',
    url: baseUrl + '/role/update',
    showError: true,
    showSuccess: true,
    message: '修改角色',
  },

  delRole: {
    method: 'delete',
    url: baseUrl + '/role/delete',
    showError: true,
    showSuccess: true,
    message: '删除角色',
  },

  /** ***********  用户管理页 end    ************ */

  /** ***********  个人中心页 start  ************ */

  // 获取风控报告处理进度
  getRiskTaskInfo: {
    method: 'get',
    url: baseUrl + '/getRiskTaskInfo',
    message: '获取风控报告处理进度',
  },

  // 获取船舶查询统计列表
  getShipSearchList: {
    method: 'post',
    url: baseUrl + '/getShipSearchList',
    message: '获取船舶查询统计列表',
  },

  // 获取船舶查询统计柱状图
  getShipSearchChart: {
    method: 'get',
    url: baseUrl + '/getShipSearchChart',
    message: '获取船舶查询统计柱状图',
  },

  // 获取预定风控报告列表
  getReportList: {
    method: 'post',
    url: baseUrl + '/report/list',
    message: '获取预定风控报告列表',
  },

  // 获取预定风控报告柱状图数据
  getReportChartData: {
    method: 'get',
    url: baseUrl + '/report/chart',
    message: '获取预定风控报告柱状图数据',
  },

  // 获取全部风控报告列表
  getRiskReportList: {
    method: 'post',
    url: baseUrl + '/getRiskTaskList',
    message: '获取全部风控报告列表',
  },

  // 根据mmsi获取船舶预警信息详情列表
  getMissionAlertByMMSI: {
    method: 'get',
    url: baseUrl + '/ship/mission/alert/search',
    message: '根据mmsi获取船舶预警信息详情列表',
  },
  // 根据mmsi获取船舶预警标签(台风，战区，大风，违规，PSC)
  getMissionAlertTagByMMSI: {
    method: 'get',
    url: baseUrl + '/ship/mission/alert/types',
    message: '根据mmsi查询预警详情列表',
  },
  // 获取省市区列表
  getProvince: {
    method: 'get',
    url: baseUrl + '/provinces',
    message: '获取省市区列表',
  },

  // 获取个人中心-业务类型
  getAllBusinessType: {
    method: 'get',
    url: baseUrl + '/business/selectors',
    message: '获取业务类型',
  },

  /** ***********  个人中心页 end    ************ */

  /** ***********  业务推荐 start  ************ */

  downloadCompanyFile: {
    method: 'get',
    url: baseUrl + '/business/download',
    message: '导出业务推荐表格Excel',
    responseType: 'blob',
  },

  getCountByType: {
    method: 'get',
    url: baseUrl + '/business/count',
    message: '获取船舶数量',
  },

  getShipTotal: {
    method: 'get',
    url: baseUrl + '/business/ships',
    message: '获取船舶总数',
  },

  getBusinessList: {
    method: 'get',
    url: baseUrl + '/business/list',
    message: '获取船舶及公司列表',
  },

  fuzzyQueryCompanyName: {
    method: 'get',
    url: baseUrl + '/business/company',
    message: '查询公司名称',
  },

  /** ***********  业务推荐 end  ************ */

  /** ***********  事件回放 start  ************ */

  getAroundShipsByID: {
    method: 'get',
    url: baseUrl + '/reply/event/area/ships',
    message: '根据ID获取事故回放周边船舶',
    timeout: 1000 * 60,
  },

  savePlaybackEvent: {
    method: 'post',
    url: baseUrl + '/reply/event/addAll',
    message: '保存事件',
  },

  updatePlaybackShip: {
    method: 'post',
    url: baseUrl + '/reply/event/ship/update',
    message: '事件回放船舶更新',
  },

  updatePlaybackEvent: {
    method: 'post',
    url: baseUrl + '/reply/event/update',
    message: '更新事件',
  },

  getPlaybackEventList: {
    method: 'post',
    url: baseUrl + '/reply/event/search',
    message: '获取事件回放列表',
  },

  getPlaybackEvent: {
    method: 'get',
    url: baseUrl + '/reply/event/ship/list',
    message: '获取事件信息',
  },

  deletePlayBackEvent: {
    method: 'get',
    url: baseUrl + '/reply/event/delete',
    message: '删除事件信息',
  },

  /** ***********  事件回放 end    ************ */

  /** ***********  其它 start  ************ */

  // 提交反馈信息
  sendFeedback: {
    method: 'post',
    url: baseUrl + '/addFeedback',
    showError: true,
    message: '提交反馈信息',
  },
  // 调用google翻译
  googleTranslate: {
    method: 'post',
    url: baseUrl + '/datacenter/standard/translate/google',
  },
  // 添加船舶反馈信息
  sendShipFeedback: {
    method: 'post',
    url: baseUrl + '/datacenter/standard/ship/feedback/add',
    showError: true,
    message: '添加船舶反馈信息',
  },
  /** ***********  其它 end    ************ */

  /** ***********  船舶图片管理 start    ************ */
  getShipPicture: {
    method: 'get',
    url: baseUrl + '/ship/mission/img/list',
    showError: true,
    message: '获取船舶图片',
  },
  addShipPicture: {
    method: 'post',
    url: baseUrl + '/ship/mission/img/add',
    showError: true,
    message: '添加船舶图片',
  },
  updateShipPicture: {
    method: 'post',
    url: baseUrl + '/ship/mission/img/update',
    showError: true,
    message: '修改船舶图片',
  },
  delShipPicture: {
    method: 'get',
    url: baseUrl + '/ship/mission/img/del',
    showError: true,
    message: '删除船舶图片',
  },
  multiShipPicture: {
    method: 'post',
    url: baseUrl + '/ship/mission/img/add/multi',
    showError: true,
    message: '批量上传船舶图片',
  },
  /** ***********  船舶图片管理 end   ************ */

  /** ***********  违规查询 start    ************ */
  simpleQueryViolationList: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/hsjvio/query',
    showError: true,
    message: '船舶违规查询',
  },
  violationTagList: {
    method: 'get',
    url: baseUrl + '/datacenter/standard/hsjvio/types',
    showError: true,
    message: '违规标签列表',
  },
  /** ***********  违规查询 end    ************ */
}
